import React, { useEffect, useReducer } from "react"
import { Context } from "./components/context"

const initialState = {
  cardState: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
  },
  allTrue: false,
}

const checkTrueValuesEqualsAllElements = obj => {
  return (
    Object.values(obj).filter(element => element).length ===
    Object.values(obj).length
  )
}

const reducer = (state, action) => {
  switch (action.type) {
    case "setCardToTrue":
      return {
        ...state,
        cardState: {
          ...state.cardState,
          [action.payload]: true,
        },
      }
    case "setAllTrue":
      return {
        ...state,
        allTrue: true,
      }
    default:
      return state
  }
}

const CardsContext = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    if (checkTrueValuesEqualsAllElements(state.cardState) && !state.allTrue) {
      dispatch({ type: "setAllTrue" })
    }
  }, [state])
  return (
    <Context.Provider value={{ state, dispatch }}>
      {props.children}
    </Context.Provider>
  )
}

export default CardsContext
